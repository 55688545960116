//////////   Media Quiery Mixin

@mixin respond($breakpoint) {
    @if $breakpoint == phone {
        @media only screen and (min-width: 320px) { @content };
    }
    @if $breakpoint == tablet-small {
        @media only screen and (min-width: 768px) { @content };
    }
    @if $breakpoint == tablet-large {
        @media only screen and (min-width: 834px) { @content }; ;
    }
    @if $breakpoint == desktop-small {
        @media only screen and (min-width: 1025px) { @content };
    }
    @if $breakpoint == desktop-medium {
        @media only screen and (min-width: 1280px) { @content };
    }
    @if $breakpoint == desktop-large {
        @media only screen and (min-width: 1441px) { @content };
    }
    @if $breakpoint == desktop-xl {
        @media only screen and (min-width: 1921px) { @content };
    }
}