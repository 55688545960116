#login .form {

    &__field {
        display: flex;
        justify-content: space-between;
        flex-flow: column-reverse;
        align-items: center;
        height: 5.6rem;
        margin-bottom: 0px;

        @include respond(tablet-small) {
            flex-flow: unset;
        }
        @include respond(desktop-small) {
            height: unset;
        }
    }

    &__label {
        font-family: roboto;
        font-weight: bold;
        color: $darkest-green;
    
        &--big {
            font-size: 2rem;
            margin-bottom: 1rem;

            @include respond(tablet-small) {
                font-size: 1.96rem;
                margin-bottom: 1.26rem;
            }
            @include respond(tablet-large) {
                margin-bottom: 1.26rem;
            }
            @include respond(desktop-small) {
                font-size: 1.6rem;
                margin-bottom: .8rem;
                margin-top: 1.4rem;
            }
            @include respond(desktop-large) {
                font-size: 2rem
            }
        }
    }

    &__input {
        font-family: 'Roboto';
        border: 2px solid #7a7777;
        opacity: 1 !important;
        border-radius: 5px;
        background: #f9fafb;

        &--big {
            font-size: 2rem;
        }

        &:focus-within {
            border: 2px solid rgb(77, 144, 254);
        }

        & > * {
            background-color: $light-gray;

            @include respond(desktop-small){
                background-color: #F9FAFB;
                padding: 8px 13.5px;
                font-size: 1.6rem;
            }
            @include respond(desktop-large){
                font-size: 2rem;
            }

            &::placeholder {
                color: $dark-gray;
            }

            &:focus {
                border: none;
            }
        }
    }

    &__actions {
        display: flex;
        flex-flow: column-reverse;

        @include respond(tablet-small) {
            flex-flow: column;
        }
        @include respond(desktop-small) {
            display: flex;
            display: flex;
            margin-top: 15px;
        }
    }


    &__checkbox {
        font-family: 'Roboto';
        color: $darkest-green;
        font-weight: 400;
        font-size: 1.8rem;

        @include respond(desktop-small){
            font-size: 1.3rem;
        }
        @include respond(desktop-large){
            font-size: 1.8rem;
        }
        
        .hidden {
            cursor: pointer;
            position: absolute;
            outline: 1px solid;
            top: 0px;
            left: 2px;
            width: 15px;
            height: 15px;
            
            @include respond(tablet-small) {
                top: 0px;
                left: 2px;
                width: 15px;
                height: 15px;
            }
            @include respond(tablet-large) {
                top: 0px;
                left: 2px;
                width: 15px;
                height: 15px;
            }
            @include respond(desktop-small) {
                top: 3px;
                left: 2px;
                width: 11px;
                height: 11px;
            }
        }
    }

    &__anchor-tag {
        text-decoration: none;

        &--big {
            font-size: 1.8rem;
            letter-spacing: .48px;
            font-family: 'Roboto';
            font-weight: 500;

            @include respond(desktop-small){
                font-size: 1.3rem;
            }
            @include respond(desktop-medium){
                font-size: 1.8rem;
            }
        }

        &--light {
            color: #F5A623;
        }
    }

    &__button {
        color: $darkest-green;

        &:hover {
            background-color: $darker-mint;
        }

        .visible.content {
            margin-right: 0;
        }

        &--big {
            height: 5.2rem;
            width: 100%;
            font-size: 2.1rem;

            @include respond(desktop-small) {
                height: unset;
                width: 50%;
                font-size: 1.6rem;
                padding: 11px 0;
            }
            @include respond(desktop-large) {
                font-size: 2rem;
            }
        }

        &--light {
            background-color: #D5EADD;
            color: #1e3a40;
            font-weight: bold;
            font-family: 'Roboto';
        }

        @include respond(tablet-small) {
            .ui.animated.button .hidden.content {
                display: none;
            }
        }
        @include respond(tablet-large) {
            .ui.animated.button .hidden.content {
                display: none;
            }
        }
        @include respond(phone) {
            .ui.animated.button .hidden.content {
                display: none;
            }
        }
    }

    .ui.checkbox input:checked:focus~label:before {
        background: $dark-gray;
    }

    .ui.checkbox .box:before, .ui.checkbox label:before {
        position: absolute;
        top: 0px;
        left: 2px;
        width: 15px;
        height: 15px;
        color: $darkest-green;
        content: '';
        background: #fff;
        transition: border .1s ease,opacity .1s ease,transform .1s ease,box-shadow .1s ease;
        border: 1px solid black;

        @include respond(tablet-small) {
            top: 0px;
            left: 2px;
            width: 15px;
            height: 15px;
        }
        @include respond(tablet-large) {
            top: 0px;
            left: 2px;
            width: 15px;
            height: 15px;
        }
        @include respond(desktop-small) {
            top: 3px;
            left: 2px;
            width: 11px;
            height: 11px;
        }
    }

    .ui.checkbox .box:before, .ui.checkbox label:after {
    
        top: 0px;
        left: 1px;
        color: white;
        font-size: 12px;

        @include respond(desktop-small) {
            width: 11px;
            height: 11px;
            top: 3;
            left: 2;
        }
    }

    .ui.checkbox .box, .ui.checkbox label {
        color: $darkest-green;
        padding-left: 2rem;
    }
}

.field {
    @include respond(tablet-small) {
        margin: 0 0 14px;
    }
    @include respond(tablet-large) {
        margin: 0 0 14px;
    }
    @include respond(phone) {
        margin: 0 0 14px;
    }
    @include respond(desktop-small) {
        margin: 0 0 10px;
    }
}