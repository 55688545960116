#login .btn-inverted {
    font-size: 1.3rem;
    border: 2px solid black;
    border-radius: 5px;
    color: $darkest-green;
    padding: .7rem 3rem;
    font-family: 'Roboto';
    
    &--light {
        background-color: initial;

        @include respond(desktop-small){
            background-color: #e9e9ea;
        }
        
        &:hover {
            background-color: $sunflower-yellow;
            border-color: $sunflower-yellow;
        }
    }    
}