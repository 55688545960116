body {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
}

h1, h2, h3, h4, h5 {
    font-family: 'Roboto Slab', serif;
    font-weight: 400;
}

.mission-statement {
    font-size: 2rem;
    font-family: 'Roboto Slab';
    font-weight: 900;
    color: $darkest-green;
    line-height: 1.6;
    
    @include respond(tablet-small) {
        line-height: 40px;
        font-size: 3rem;
    }
    @include respond(desktop-small) {
        line-height: 25px;
        font-size: 1.8rem;
    }
    @include respond(desktop-large) {
        line-height: 38px;
        font-size: 3rem;
    }
    @include respond(desktop-xl) {
        font-size: 3.5rem;
        line-height: 45px;
    }
}

.copyright {
    font-family: Roboto;
    font-weight: 400;
    font-size: 1.5rem;

    @include respond(desktop-small) {
        font-size: 1.1rem;
    }
    @include respond(desktop-large) {
        font-size: 1.5rem;
    }

    &--light {
        color: #FFFFFF;
    }
}