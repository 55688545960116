$white: #FFFFFF;

$dark-gray: #7A7777;
$light-gray: #F9FAFB;

$sunflower-yellow: #F5A623;

$darkest-green: #1A2D31;
$foam-green: #748680;

$darker-mint: #A1C0AD;
$dark-mint: #B4D6C1;
$mint: #D5EADD;