.logo {
    max-height: 2.5rem;
    @include respond(desktop-small){
        max-height: 2.2rem;
    }
    @include respond(desktop-large){
        max-height: 2.5rem;
    }
    @include respond(desktop-xl){
        max-height: 3rem;
    }
}