.login {
    display: flex;
    flex-flow: column;
    
    @include respond(desktop-small) {
        height: 100vh;
        flex-flow: row;
    }

    &__logo-container {
        position: absolute;
        top: 2.5rem;
        left: 5.4%;

        @include respond(tablet-small) {
            top: 4.9rem;
            left: 4.9rem;
        }
        @include respond(desktop-small) {
            top: 3.5rem;
            left: 3.6rem;
        }
    }

    &__left-container {
        display: flex;
        justify-content: center;
        position: relative;

        @include respond(tablet-small) {
            min-height: 60vh;
        }
        @include respond(desktop-small) {
            min-height: unset;
            width: 50%;
            display: unset;
        }
    }

    &__right-container {
        background-position: 0px -41px;
        position: relative;
        background-image: url("../../images/Login/Graphic\ Elements/Login-Background-Mobile.png");
        background-size: cover;
        overflow: hidden;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: space-between;

        @include respond(tablet-small) {
            min-height: 40vh;
            background-position: 0px -13px;
            background-image: url("../../images/Login/Graphic\ Elements/Login-Background-Tablet.png");
        }
        @include respond(desktop-small) {
            min-height: unset;
            background-position: unset;
            align-items: flex-end;
            width: 50%;
            background-image: url("../../images/Login/Graphic\ Elements/Login-Background-Desktop-Lg.png");
        }
    }

    &__form-container {
        width: 85%;
        margin-top: 7.4rem;
        margin-bottom: 2.45rem;

        @include respond(tablet-small) {
            width: 70%;
            margin-top: 15.7rem;
            margin-bottom: 9.8rem;
        }
        @include respond(tablet-large) {
            width: 50%;
            margin-top: 23.5rem;
        }
        @include respond(desktop-small) {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 70%;
            margin-top: unset;
            margin-bottom: unset;
            transform: translate(-50%, -50%);
        }
        @include respond(desktop-large) {
            width: 60%;
        }  
        @include respond(desktop-xl) {
            width: 55%;
        }    
    }

    &__form-title {
        font-family: 'Roboto Slab';
        font-size: 5rem;
        font-weight: 900;
        margin-bottom: 1.7rem;
        line-height: 1.2;
        color: $darkest-green;

        @include respond(tablet-small) {
            margin-bottom: 1.96rem; 
        }
        @include respond(desktop-small) {
            margin-bottom: unset;
            font-size: 4rem;
        }
        @include respond(desktop-large) {
            font-size: 5rem;
        }
        @include respond(desktop-xl) {
            font-size: 7rem;
        }
    }


    &__mission-statement-container {
        width: 95%;
        text-align: center;
        padding: 1rem 0 2rem 0;
        margin: 3.22rem 0 2.8rem 0;
        background-color: white;
        border-radius: 13px;
        box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);

        @include respond(tablet-small) {
            margin: unset;
            margin-top: 5.6rem;
            margin-bottom: 4.2rem;
            padding: 1.8rem 2.8rem 2.3rem 2.8rem;
            letter-spacing: .8px;
            width: 60%;
        }
        @include respond(tablet-large) {
            padding: 2.1rem 2.8rem;
            width: 50%;
        }
        @include respond(desktop-small) {
            width: unset;
            box-shadow: unset;
            padding: unset;
            margin-bottom: unset;
            margin-top: 2.1rem;
            background-color: unset;
            border-radius: unset;
            margin-right: 3rem;
            text-align: end;
        }
        
    }

    &__copyright-container {
        background-color: $darkest-green;
        height: 3.8rem;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        @include respond(desktop-small) {
            justify-content: flex-end;
            padding-right: 25px;
            height: 2.5rem;
        }
        @include respond(desktop-large) {
            height: 3.8rem;
        }
    }
}

#desktop_curve-xl {
    display: none;
    height: 100vh;
    position: absolute;
    transform: scale(2);
    left: -3px;
    fill: white;
    filter: drop-shadow(0 10px 20px rgba(0,0,0,0.19)) drop-shadow(0 6px 6px rgba(0,0,0,0.23));
    
    @include respond(desktop-xl) {
        display: inline-block;
    }
}

#desktop_curve-large {
    display: none;
    height: 100vh;
    position: absolute;
    transform: scale(2);
    left: -1px;
    fill: white;
    filter: drop-shadow(0 10px 20px rgba(0,0,0,0.19)) drop-shadow(0 6px 6px rgba(0,0,0,0.23));
    
    @include respond(desktop-large) {
        display: inline-block;
    }
    @include respond(desktop-xl) {
        display: none;
    }
}

#desktop_curve-medium {
    display: none;
    height: 100vh;
    position: absolute;
    transform: scale(2);
    left: -1px;
    fill: white;
    filter: drop-shadow(0 10px 20px rgba(0,0,0,0.19)) drop-shadow(0 6px 6px rgba(0,0,0,0.23));
    
    @include respond(desktop-medium) {
        display: inline-block;
    }
    @include respond(desktop-large) {
        display: none;
    }
}

#desktop_curve-small {
    display: none;
    height: 100vh;
    position: absolute;
    transform: scale(2);
    left: -1px;
    fill: white;
    filter: drop-shadow(0 10px 20px rgba(0,0,0,0.19)) drop-shadow(0 6px 6px rgba(0,0,0,0.23));
    
    @include respond(desktop-small) {
        display: inline-block;
    }
    @include respond(desktop-medium) {
        display: none;
    }
}

#tablet_curve-big {
    display: none;
    width: 100%;
    transform: scale(1.5);
    fill: white;
    margin-top: -2px;
    filter: drop-shadow(0 10px 20px rgba(0,0,0,0.19)) drop-shadow(0 6px 6px rgba(0,0,0,0.5));

    @include respond(tablet-large) {
        display: inline-block;
    }
    @include respond(desktop-small) {
        display: none;
    }
}

#tablet_curve-small {
    display: none;
    width: 100%;
    transform: scale(1.1);
    fill: white;
    margin-top: -2px;
    filter: drop-shadow(0 10px 20px rgba(0,0,0,0.19)) drop-shadow(0 6px 6px rgba(0,0,0,0.5));

    @include respond(tablet-small) {
        display: inline-block;
    }
    @include respond(tablet-large) {
        display: none;
    }
}

#phone_curve {
    display: inline-block;
    width: 100%;
    transform: scale(1.5);
    fill: white;
    margin-top: -1px;
    filter: drop-shadow(0 10px 20px rgba(0,0,0,0.19)) drop-shadow(0 6px 6px rgba(0,0,0,0.5));

    @include respond(tablet-small) {
        display: none;
    }
}
